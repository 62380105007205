import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 2px solid;
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Impressum"
        siteUrl="https://bloggingfordevs.com"
        description="Impressum"
        pathname="impressum/"
      />
      <ProductHuntBanner />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Wrapper>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            404
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Bummer, this page is missing!
            <br />
            <br />
            Maybe you're looking for{' '}
            <StyledLink to="/">the newsletter</StyledLink>,{' '}
            <StyledLink to="/blog/">the blog</StyledLink>, or{' '}
            <StyledLink to="/pro/">the community</StyledLink>?
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
